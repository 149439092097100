import debounce from './debounce';

const handleInput = debounce(function (event) {
  let fields = [
    'form[data-auto-submit=true] input',
    'form[data-auto-submit=true] select',
    'form[data-auto-submit=true] textarea',
  ];
  
  if (event.target.matches(fields.join(', '))) {
    event.target.form.requestSubmit();
  }
}, 500);

window.addEventListener('input', handleInput);
window.addEventListener('change', handleInput);
