// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus";

const application = Application.start();
application.debug = true

import Clipboard from "stimulus-clipboard"
application.register("clipboard", Clipboard)

import { Dropdown, Modal, Tabs, Toggle } from "tailwindcss-stimulus-components"
application.register("dropdown", Dropdown);
application.register("modal", Modal);
application.register("tabs", Tabs);
application.register("toggle", Toggle);

import AddressVerifierController from './address_verifier_controller'
application.register("address-verifier", AddressVerifierController)

import CheckAllController from './check_all_controller'
application.register("check-all", CheckAllController)

import ComboSelectController from './combo_select_controller'
application.register("combo-select", ComboSelectController)

import FilterController from './filter_controller'
application.register("filter", FilterController)

import GPSController from "./gps_controller"
application.register("gps", GPSController)

import HereMapsMapController from './here_maps_map_controller'
application.register("here-maps-map", HereMapsMapController)

import HighlighterController from './highlighter_controller'
application.register("highlighter", HighlighterController)

import HovercardController from './hovercard_controller'
application.register("hovercard", HovercardController)

import MenuToggleController from './menu_toggle_controller'
application.register("menu-toggle", MenuToggleController)

import MessagePreviewController from './message_preview_controller'
application.register("message-preview", MessagePreviewController)

import RefreshableController from './refreshable_controller'
application.register("refreshable", RefreshableController)

import RemovableController from './removable_controller'
application.register("removable", RemovableController)

import SidebarController from './sidebar_controller'
application.register("sidebar", SidebarController)

import StripePaymentController from './stripe_payment_controller'
application.register("stripe-payment", StripePaymentController)

import TextInsertionController from './text_insertion_controller'
application.register("text-insertion", TextInsertionController)

import TextareaAutogrowController from './textarea_autogrow_controller'
application.register("textarea-autogrow", TextareaAutogrowController)

import TransitionController from './transition_controller'
application.register('transition', TransitionController)

import ValueToggleController from './value_toggle_controller'
application.register("value-toggle", ValueToggleController)
