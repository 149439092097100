import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        url: String,
        enabled: Boolean
    }

    connect() {
        if (!this.enabledValue) return;

        setTimeout(() => {
            fetch(this.urlValue)
                .then(response => response.text())
                .then(response => {
                    let newElement = document.createElement('template');
                    newElement.innerHTML = response;
                    this.element.after(newElement.content.firstElementChild);
                    this.element.remove();
                });
        }, 5000);
    }

}
