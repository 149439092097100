import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["card"];
  static values = { url: String };

  load() {
    if (!this.hasCardTarget) {
      fetch(this.urlValue)
        .then((r) => r.text())
        .then((html) => {
          const fragment = document.createRange().createContextualFragment(html);
          this.element.appendChild(fragment);
        })
    }
  }
}