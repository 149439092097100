import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    selector: String
  }
  connect() {
    const target = document.querySelector(this.selectorValue)
    this.element.remove()
    if (!target) return

    target.classList.add('bg-yellow-200', 'transition-all', 'duration-500')
    setTimeout(() => {
      target.classList.remove('bg-yellow-200')
    }, 1000)
  }
}
