import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
    static targets = ['control', 'checkable']

    toggle() {
        let status = this.controlTarget.checked;
        this.checkableTargets.forEach(checkbox => {
            if (checkbox.checked == status) return ;
            checkbox.checked = status;
            
            let form = checkbox.form
            let data = new FormData(form)
            let request = new FetchRequest(form.method, form.action, {
                body: data
            })
            request.perform();
        })
    }
}
