import { Controller } from '@hotwired/stimulus';
import { loadAPIs } from '../src/geography/google_maps_provider'
import dayjs from "dayjs";
const ETA_LIMIT = 4;
const ETA_LIMIT_SECONDS = ETA_LIMIT * 60 * 60;

export default class extends Controller {
    static targets = ['eta', 'map']
    static values = {
        locationUpdatedAt: String,
        homeLatitude: Number,
        homeLongitude: Number,
        spLatitude: Number,
        spLongitude: Number,
        mapProvider: String,
        homeImage: String,
        spImage: String,
    }

    connect() {
        if (!this.hasMapTarget) return
        if (!this.hasCoordinates()) return

        switch(this.mapProviderValue) {
            case 'google':
                this.setupGoogleMap();
                break;
            default:
                console.log(`no support for ${this.mapProviderValue}`);
        }
    }

    async setupGoogleMap() {
        await loadAPIs()
        const origin = new google.maps.LatLng(this.homeLatitudeValue, this.homeLongitudeValue);
        const destination = new google.maps.LatLng(this.spLatitudeValue, this.spLongitudeValue);
        const bounds = new google.maps.LatLngBounds();
        bounds.extend(origin);
        bounds.extend(destination);

        const mapOptions = {
            zoom: 12,
            center: {lat: this.homeLatitudeValue, lng: this.homeLongitudeValue},
            mapTypeId: google.maps.MapTypeId.ROADMAP,

            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
        };

        let map = new google.maps.Map(this.mapTarget, mapOptions)
        map.fitBounds(bounds);
        map.panToBounds(bounds);

        const homeMarker = new google.maps.Marker({
            position: new google.maps.LatLng(this.homeLatitudeValue, this.homeLongitudeValue),
            icon: this.homeImageValue,
            map,
        });

        const serviceProviderMarker = new google.maps.Marker({
            position: new google.maps.LatLng(this.spLatitudeValue, this.spLongitudeValue),
            icon: this.spImageValue,
            map,
        });

        const directionsService = new google.maps.DirectionsService();
        const directionsDisplay = new google.maps.DirectionsRenderer({
            map,
            preserveViewport: true
        });

        const drivingOptions = {
            departureTime: new Date(),
            trafficModel: 'pessimistic'
        };

        directionsService.route({
            origin,
            destination,
            travelMode: google.maps.TravelMode.DRIVING,
            drivingOptions,
        }, (response, status) => {
            if (status != google.maps.DirectionsStatus.OK) return;

            const bounds = new google.maps.LatLngBounds();
            const { legs } = response.routes[0];
            const polyline = new google.maps.Polyline({
                path: [],
                strokeColor: 'rgba(0, 128, 255, 0.7)',
                strokeWeight: 5,
            });

            const locationUpdatedAt = this.locationUpdatedAtValue;
            if (locationUpdatedAt) {
                const { duration_in_traffic } = legs[0];
                const now = dayjs();
                const locationAge = now.diff(dayjs(locationUpdatedAt), 'second')
                const arrivalTime = now.subtract(locationAge, 'second').add(duration_in_traffic.value, 'second')

                if (duration_in_traffic.value > locationAge && duration_in_traffic.value <= ETA_LIMIT_SECONDS) {
                    this.etaTarget.innerHTML = `ETA ${arrivalTime.format("h:mm A")}`;
                }
            }

            const routeSteps = legs.map(({steps}) => {
                return steps.map(step => step.path);
            }).flat();
            routeSteps.flat().forEach((item) => {
                polyline.getPath().push(item);
                bounds.extend(item);
            })

            polyline.setMap(map);
        });
    }

    hasCoordinates() {
        return this.hasHomeLatitudeValue && !isNaN(this.homeLatitudeValue) &&
            this.hasHomeLongitudeValue && !isNaN(this.homeLongitudeValue) &&
            this.hasSpLatitudeValue && !isNaN(this.spLatitudeValue) &&
            this.hasSpLongitudeValue && !isNaN(this.spLongitudeValue);
    }
}
