import * as ActiveStorage from "@rails/activestorage"
import "@hotwired/turbo-rails"

ActiveStorage.start()

import "./controllers"
import flatpickr from "flatpickr"

import LocalTime from "local-time"
LocalTime.start()

const runningSelenium =
    navigator.webdriver ||
    window.document.documentElement.getAttribute("webdriver");

document.addEventListener("turbo:load", function () {
    // dont use custom inputs while running under selenium
    if (runningSelenium) { return; }

    flatpickr("[type=datetime-local].flatpickr-input", {
        allowInput: true, // allows html5 validation (required)
        enableTime: true,
        dateFormat: 'Y-m-d h:i K'
    });

    flatpickr("[type=date].flatpickr-input", {
        allowInput: true, // allows html5 validation (required)
        enableTime: false,
    });
});

import "./src/auto-submit-form"
// import "../styles/styles" # TODO: remove this line

const forceReload = 1; // just for triggering stuff

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

