import { Loader } from "@googlemaps/js-api-loader"

const apiKey = document.getElementById('googleApiKey').content
const loader = new Loader({
    apiKey: apiKey,
    version: "weekly",
})

let geocoder;

export async function loadAPIs() {
    await loader.load()
    await window.google.maps.importLibrary("maps")
    await window.google.maps.importLibrary("places")
}

export async function geocode(address) {
    await loadAPIs()
    if (!window.google) return null;

    if (!geocoder) {
        geocoder = new google.maps.Geocoder();
    }

    return geocoder
        .geocode({ address: address })
        .then(result => {
            if (result.results.length == 0) return null;

            return {
                latitude: result.results[0].geometry.location.lat(),
                longitude: result.results[0].geometry.location.lng(),
                partial: !!result.results[0].partial_match
            };
        });
}

export function verifyAddress(address) {
    return geocode(address)
        .then(results => {
            return results && !results.partial
        })
        .catch(error => {
            return false;
        })
}