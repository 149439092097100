import { Controller } from '@hotwired/stimulus'
import { loadAPIs } from '../src/geography/google_maps_provider'

export default class extends Controller {
    static targets = ['map', 'serviceProvider']
    connect() {
        this.markers = []

        this.setupMap()
    }

    async setupMap() {
        await loadAPIs()

        this.bounds = new google.maps.LatLngBounds();
        const mapOptions = {
            zoom: 5,
            center: {
                lat: 39.8283,
                lng: -98.5795,
            },
            mapTypeId: google.maps.MapTypeId.ROADMAP,

            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
        }

        this.map = new google.maps.Map(this.mapTarget, mapOptions)
    }

    serviceProviderTargetConnected(sp) {
        if (!this.map) {
            setTimeout(() => {
                this.serviceProviderTargetConnected(sp)
            }, 100)

            return
        }

        console.log("Adding SP", sp)

        if (!sp.dataset.gpsLat) {
            this.removeServiceProvider(sp)
        } else {
            this.addServiceProvider(sp)
        }

        // sp.remove()
    }

    addServiceProvider(sp) {
        console.log("adding sp", sp)
        let coords = {
            lat: parseFloat(sp.dataset.gpsLat),
            lng: parseFloat(sp.dataset.gpsLng),
        }

        if (this.markersForServiceProvider(sp).length === 0) {
            this.markers.push(this.makeMarkerForServiceProvider(sp))
        }

        this.markersForServiceProvider(sp)
            .forEach(marker => {
                marker.setPosition(coords)
            })

        this.bounds.extend(coords)
        this.map.fitBounds(this.bounds);
    }

    markersForServiceProvider(sp) {
        return this.markers.filter(x => x.sp_id === sp.dataset.gpsId)
    }

    makeMarkerForServiceProvider(sp) {
        let coords = {
            lat: parseFloat(sp.dataset.gpsLat),
            lng: parseFloat(sp.dataset.gpsLng),
        }

        let popup = new google.maps.InfoWindow({
            content: sp.innerHTML,
            ariaLabel: sp.dataset.gpsName,
        })

        let marker = new google.maps.Marker({
            position: coords,
            map: this.map,
            title: sp.dataset.gpsName,
        })

        marker.sp_id = sp.dataset.gpsId
        marker.addListener("click", () => {
            popup.open({
                anchor: marker,
                map: this.map
            })
        })

        return marker
    }

    removeServiceProvider(sp) {
        console.log("Removing sp", sp)
        this.markersForServiceProvider(sp)
            .forEach(marker => {
                marker.setMap(null)
                this.markers.splice(this.markers.indexOf(marker), 1)
            })
    }

}