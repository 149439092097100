import { Controller } from "@hotwired/stimulus";
import debounce from '../src/debounce'

export default class extends Controller {
  static targets = ["appointment", "body", "preview", "characterCount"];

  initialize() {
    this.preview = debounce(this.preview, 500).bind(this);
  }

  connect() {
    this.preview();
  }

  preview() {
    fetch("/messaging/message_preview", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        body: this.bodyTarget.value,
        appointment: this.appointmentTarget.value,
      }),
    }).then((r) => r.text())
      .then((html) => {
        // using global regex cause test environment doesn't have replaceAll
        const copy = html.indexOf("<p") == -1 ? html.replace(/\n/g, "<br/>") : html;
        this.previewTarget.srcdoc = copy;
        this.characterCountTarget.innerHTML = `(${copy.length} characters)`;
      });
  }
}
