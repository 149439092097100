import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['cardElement', 'cardErrors', 'form', 'submit'];

  connect() {
    let submitting = false;
    let controller = this;
    let stripe = Stripe(this.data.get('key'));
    let elements = stripe.elements();
    let card = elements.create('card', {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
            color: '#aab7c4'
        }
      },
      invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
      }
    });

    card.mount(this.cardElementTarget);   
    card.addEventListener('change', function(event) {
      if (event.error) {
        controller.cardErrorsTarget.textContent = event.error.message;
      } else {
        controller.cardErrorsTarget.textContent = '';
      }
    });

    function submitHandler(event) {
      event.preventDefault();
      if (submitting) { return; }

      const clientSecret = controller.data.get('clientSecret');
      const intention = controller.data.get('intention');
      const baseVerb = controller.submitTarget.innerText.slice(0, -1)
      controller.submitTarget.disabled = true;
      controller.submitTarget.innerText = `${baseVerb}ing…`
      submitting = true;

      switch(intention) {
        case 'setup':
          var action = stripe.confirmCardPayment(clientSecret, {
            payment_method: {
              card: card,
            }
          });
          break;
        case 'update':
          var action = stripe.confirmCardSetup(clientSecret, {
            payment_method: {
              card: card
            }
          });
          break;
      }

      action.then(result => {
        if (result.error) {
          alert(result.error.message);
          controller.submitTarget.disabled = false;
          controller.submitTarget.innerText = `${baseVerb}e`
          submitting = false;
        } else {
          setTimeout(function() {
            location.reload();
          }, 2500);
        }
      }).catch(error => {
        controller.submitTarget.disabled = false;
        controller.submitTarget.innerText = `${baseVerb}e`
        submitting = false;

        console.log(error);
      });
    };

    controller.formTarget.addEventListener('submit', submitHandler);
    controller.submitTarget.addEventListener('click', submitHandler);
  }
}
