import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['activeElement', 'inactiveElement', 'input']
  static values = {
    activeValues: String
  }

  updateViews() {
    if (this.activeValuesValue.includes(this.inputTarget.value)) {
      this.activeElementTarget.classList.remove('hidden');
      this.inactiveElementTarget.classList.add('hidden');
    } else {
      this.activeElementTarget.classList.add('hidden');
      this.inactiveElementTarget.classList.remove('hidden');
    }
  }

  connect() {
    this.updateViews();
  }
}
