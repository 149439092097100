import { Controller } from "@hotwired/stimulus";
import { enter, leave } from 'el-transition'

export default class extends Controller {
    connect() {
        this.element.classList.remove('hidden')
        enter(this.element)
            .catch(e => {
                console.error("Error doing transition", e, this.element)
            })
    }

    leave() {
        leave(this.element).then(() => {
            this.element.remove()
        })
    }
}