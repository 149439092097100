import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["sidebar"];

  toggle(event) {
    event.preventDefault();
    let container = this.sidebarTarget.querySelector("section");
    // this.sidebarTarget.classList.toggle("hidden");
    container.classList.toggle("translate-x-full");
    container.classList.toggle("translate-x-0");
  }
}
