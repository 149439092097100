import { Controller } from "@hotwired/stimulus";
import debounce from '../src/debounce';

export default class extends Controller {
  initialize() {
    this.autogrow = this.autogrow.bind(this);
  }
  connect() {
    this.element.style.overflow = "hidden";
    const delay = this.data.get("resizeDebounceDelay") || 100;

    this.onResize = delay > 0 ? debounce(this.autogrow, delay) : this.autogrow;

    this.autogrow();

    this.element.addEventListener("input", this.autogrow);
    window.addEventListener("resize", this.onResize);
    window.addEventListener('tsc:tab-change', this.autogrow);
  }

  disconnect() {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener('tsc:tab-change', this.autogrow);
  }

  autogrow() {
    this.element.style.height = "auto"; // Force re-print before calculating the scrollHeight value.
    let height = Math.max(this.element.scrollHeight, 50);
    this.element.style.height = `${height}px`;
  }
}