import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['search', 'input', 'optionContainer', 'options']

    updateSearch(e) {
        if (this.searchTarget.value == '') {
            this.optionsTargets.forEach(option => {
                option.classList.remove('hidden');
            });

            return;
        }

        this.optionsTargets.forEach(option => {
           if (option.dataset.comboSelectSearchValue.toLowerCase().includes(this.searchTarget.value.toLowerCase())) {
               option.classList.remove('hidden');
           } else {
               option.classList.add('hidden');
           }
        });
    }

    open() {
        this.optionContainerTarget.classList.remove('hidden')
    }

    close() {
        this.optionContainerTarget.classList.add('hidden')
    }

    toggleOpen() {
        this.optionContainerTarget.classList.contains('hidden') ? this.open() : this.close();
    }

    windowClick(event) {
        if (this.element.contains(event.target)) return;
        this.close();
    }

    pickOption(event) {
        let text = event.currentTarget.dataset.comboSelectSearchValue;
        let value = event.currentTarget.dataset.comboSelectOptionId;

        this.searchTarget.value = text;
        this.inputTarget.value = value;
        this.inputTarget.dispatchEvent(new Event('input', { bubbles: true }));
        this.close();
    }
}
