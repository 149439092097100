export default function debounce(func, wait = 100) {
  var timeout = null;
  
  return function(...args) {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
}
