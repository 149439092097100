import { Controller } from "@hotwired/stimulus"
import { verifyAddress } from "../src/geography/google_maps_provider";
import debounce from '../src/debounce'

let geocoder;

export default class extends Controller {
  static targets = [ 'address1', 'address2', 'city', 'state', 'zipcode',
    'latitude', 'longitude',
    'checkmark',
  ];

  connect() {
    this.changed = debounce(this.changed, 250).bind(this);

    if (this.fullAddress) {
      this.changed();
    }
  }

  changed() {
    if (!this.filledAddress()) {
      this.setCheckmarkColor('red');
      return;
    }

    verifyAddress(this.fullAddress)
        .then(valid => {
          let color = valid ? 'green' : 'yellow';
          this.setCheckmarkColor(color);
        })

    return; // TODO : remove

    if (!geocoder && google) {
      geocoder = new google.maps.Geocoder();
    }

    geocoder.geocode({ address: this.fullAddress })
      .then(result => {
        if (result.results.length == 0) {
          this.setCheckmarkColor('red');
        } else {
          this.latitudeTarget.value = result.results[0].geometry.location.lat();
          this.longitudeTarget.value = result.results[0].geometry.location.lng();
          this.setCheckmarkColor('green');
        }
      })
      .catch(e => {
        this.setCheckmarkColor('red')
      })
  }

  filledAddress() {
    return [ this.address1Target,
      this.cityTarget,
      this.stateTarget,
    ].map(field => {
      return field.value.trim()
    }).filter(field => {
      return field && field != "";
    }).length == 3
  }

  setCheckmarkColor(color) {
    this.checkmarkTarget.classList.remove('opacity-0');
    this.checkmarkTarget.classList.remove('text-green-500');
    this.checkmarkTarget.classList.remove('text-gray-500');
    this.checkmarkTarget.classList.remove('text-red-500');
    this.checkmarkTarget.classList.remove('text-yellow-500');

    this.checkmarkTarget.classList.add(`text-${color}-500`);
  }

  get fullAddress() {
    return [ this.address1Target,
      this.address2Target,
      this.cityTarget,
      this.stateTarget,
      this.zipcodeTarget,
    ].map(field => {
      return field.value.trim()
    }).filter(field => {
      return field && field != "";
    }).join(", ")
  }

}
