import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["editor", "options"];

  insert() {
    let currentValue = this.editorTarget.value;
    let start = this.editorTarget.selectionStart || 0;
    let end = this.editorTarget.selectionEnd || 0;
    let substitution = `{{ ${this.optionsTarget.value} }}`;

    let parts = [
      currentValue.substring(0, start),
      substitution,
      currentValue.substring(end, currentValue.length),
    ];

    this.editorTarget.value = parts.join(" ");
    this.optionsTarget.value = null;
  }
}
